<template>
  <div class="data-box">
    <van-nav-bar
        title="餐厅详情"
        left-text="返回"
        right-text="添加盒饭"
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
    </van-nav-bar>
    <div class="data-box-body">
      <div class="body-div-us">
        <div class="body-div-quick">
          <div class="quick">
            <span class="span-label">快捷添加</span>
            <span class="span-text" @click="selectSpecialDate(1)">今天</span>
            <span class="span-text" @click="selectSpecialDate(2)">活动期间</span>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">日期</div>
          <div class="span-input" @click="editDate">
            <span>{{ diningDate }}</span>
            <i class="iconfont icon-riqix"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">餐点</div>
          <div class="span-input" @click="editDiningType">
            <span>{{  ["早餐","午餐","晚餐","宵夜","全部"][diningType] }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
      </div>
      <div class="body-div" v-show="mealsList.length > 0">
        <div class="body-div-title align-center">
          {{ supplierName }}
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt style="width: 33.3%;">餐食名称</dt>
              <dt style="width: 33.3%;">预定数量</dt>
              <dt style="width: 33.3%;">签收数量</dt>
            </tr>
            <tr v-for="(item,index) in mealsList" :key="index">
              <dd style="width: 33.3%;"><span class="show-line1" style="padding:0px 2px;">{{ item.str }}</span></dd>
              <dd style="width: 33.3%;">{{ item.num1 }}</dd>
              <dd style="width: 33.3%;">
                <input class="van-cell" type="number" v-model="item.signNum" placeholder="请输入数量" maxlength="8" @input="inputHandler(item,index)"/>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button class="btn" round block type="default" @click="onCancel">取消</van-button>
      <van-button class="btn" round block type="info" @click="onSure">确认签收</van-button>
    </div>
    <van-action-sheet v-model="showDiningType" :actions="mealsDiningType" @select="selectDiningType"/>
    <van-calendar v-model="showDiningDate" type="range" @confirm="selectDiningDate" :min-date="minDate" :max-date="maxDate" :default-date="defaultDate"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  canteenFoodStatistics,
  editActivityMaterials
} from "@/api/index";

export default {
  name: "orgMeals_details",
  data() {
    return {
      diningDate: "",
      minDate: new Date(),
      maxDate: new Date(),
      defaultDate: null,
      diningType: null,
      showDiningDate: false,
      showDiningType: false,
      supplierId: null,//供应商企业Id
      supplierName: "",//供应商企业名称
      mealsDiningType: [
        {type: 0,name: "早餐"},
        {type: 1,name: "午餐"},
        {type: 2,name: "晚餐"},
        {type: 3,name: "宵夜"},
        {type: 4,name: "全部"}
      ],
      mealsList: [],
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.supplierId = that.$route.query.id;
      that.supplierName = that.$route.query.name;
      that.minDate = new Date();
      that.maxDate = new Date();
      that.defaultDate = [ that.minDate, that.maxDate ];
      that.diningType = 1;
      that.selectSpecialDate(1);
    },
    getCanteenFoodStatistics(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        startDate: that.diningDate.split(" - ")[0],
        endDate: that.diningDate.split(" - ")[1],
        relateType: that.diningType,
        companyId: that.$store.getters.getCompanyId,
        pageNum: 1,
        pageSize: 9999,
        num: that.supplierId,
        type: 3
      };
      canteenFoodStatistics(params).then(res => {
        that.$tool.resValidation(res,() => {
          that.mealsList = res.data.records;
          that.mealsList.forEach(item => {
            item.signNum = item.num2 ? item.num2 : null;
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight(){
      let that = this;
      that.$router.push({
        name: 'orgMeals_details_add',
        query: {
          id: that.supplierId
        }
      });
    },
    editDate(){
      let that = this;
      that.showDiningDate = true;
    },
    selectDiningDate(date){
      let that = this;
      let start = that.$tool.dateFormat(date[0]);
      let end = that.$tool.dateFormat(date[1]);
      that.showDiningDate = false;
      that.diningDate = start + " - " + end;
      that.getCanteenFoodStatistics();
    },
    //type 1：今天；2：活动期间
    selectSpecialDate(type){
      let that = this;
      if(type === 1){
        let date = new Date();
        let start = that.$tool.dateFormat(date);
        let end = that.$tool.dateFormat(date);
        that.defaultDate = [ new Date(start),new Date(end) ];
        that.diningDate = start + " - " + end;
      }else{
        let actObjInfo = that.$store.getters.getOPDetails;
        that.defaultDate = [ new Date(actObjInfo.actStartDate), new Date(actObjInfo.actEndDate) ];
        that.diningDate = actObjInfo.actStartDate + " - " + actObjInfo.actEndDate;
      }
      that.getCanteenFoodStatistics();
    },
    editDiningType(){
      let that = this;
      that.showDiningType = true;
    },
    selectDiningType(item){
      let that = this;
      that.diningType = item.type;
      that.showDiningType = false;
      that.getCanteenFoodStatistics();
    },
    onCancel(){
      let that = this;
      that.$router.go(-1);
    },
    onSure(){
      let that = this;
      let isInput = true;
      that.mealsList.forEach(item => {
        if(item.signNum === null){
          isInput = false;
        }
      })
      if(!isInput){
        that.$toast('请输入签收数量');
        return;
      }
      editActivityMaterials(that.mealsList).then(res => {
        that.$tool.resValidation(res,() => {
          that.$toast.success('确认签收成功');
          that.$router.go(-1);
        })
      })
    },
    //签收数量输入验证
    inputHandler(item,index){
      let that = this;
      item.signNum = Number(item.signNum) > item.num1 ? item.num1 : Number(item.signNum);
      this.$set(that.mealsList, index, item);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-div-us{
  width: 90%;
  margin: 10px auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 6px;
  .body-div-quick{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .quick{
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 10px 10px;
      background-color: rgba(229,104,0,0.08);
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .span-label{
      color: #8B8FAD;
      font-size: 14px;
    }
    .span-text{
      padding: 6px 16px;
      font-size: 14px;
      color: #FD7300;
      background-color: #FFE1C8;
      border-radius: 16px;
    }
  }
  .body-div-item {
    width: 90%;
    margin: 16px 0px;
    padding: 0px 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .span-key{
      width: 20%;
      color: #3E4576;
      font-size: 14px;
    }
    .span-input{
      width: 80%;
      background-color: #F6F9FF;
      border-radius: 20px;
      padding: 6px 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #3E4576;
    }
    i{
      width: 10%;
      text-align: right;
      color: #3476FF;
      font-size: 20px;
    }
  }
}
.van-cell{
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  border-bottom-right-radius: 10px;
  border: 0px;
}
</style>